export default [
  {
    prop: 'type_id',
    label: 'Type',
    minWidth: 80,
    type: 'id'
  },
  {
    prop: 'title',
    label: 'Tiêu đề',
    minWidth: 120,
    type: 'text'
  },
  {
    prop: 'type',
    label: 'Loại mail',
    minWidth: 120,
    type: 'text'
  },
  {
    prop: 'is_active',
    label: 'Trạng thái',
    minWidth: 120,
    type: 'select',
    options: [
      {
        value: 'Đang sử dụng',
        title: 'Đang sử dụng'
      },
      {
        value: 'Không sử dụng',
        title: 'không sử dụng'
      }
    ]
  }
]
